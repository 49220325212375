'use strict';

/* SERVICE DECLARATION TEMPLATE

angular.module('rando.custom').service('nameOfYourService', function (aDependency, anotherDependency) {

    this.myMethod = function () {
        return 'Yeah it works !';
    };


});

/!\ the name of the module must be 'rando.custom', otherwise you'll get an error of a non existing module.

HINT: You can declare as many services as you whish

*/

function newsFeedService($q, $resource, $sce) {

    var self = this;
    var feedUrl = 'http://www.ecrins-parcnational.fr/api/views/flux_actus.json?display_id=flux_actus';

    this.getFeed = function () {

        var deferred = $q.defer();



        if (self.newsFeed) {

            deferred.resolve(self.newsFeed);

        } else {
            var requests = $resource(feedUrl, {}, {
                query: {
                    method: 'GET',
                    cache: true,
                    isArray: true
                }
            }, {stripTrailingSlashes: false});

            requests.query().$promise
                .then(function (data) {
                    data = angular.fromJson(data);
                    var feed = [];
                    var tempNews;
                    for (var i = 0; i < 3; i++) {
                        tempNews = data[i];
                        tempNews.node_title = $sce.trustAsHtml(tempNews.node_title);
                        tempNews.chapeau = $sce.trustAsHtml(tempNews.chapeau);
                        feed.push(tempNews);
                    }
                    self.newsFeed = feed;
                    deferred.resolve(feed);
                });

        }

        return deferred.promise;

    };



}

angular.module('rando.custom').service('newsFeedService', newsFeedService);


const appendTag = (tagName, targetSelector, attributes, callback) => {
    const target = angular.element(document.querySelector(targetSelector));
    const tag = angular.element(document.createElement(tagName));
    typeof callback === 'function' && tag.on('load', callback);
    tag.attr(attributes);
    target.append(tag);
};
const loadStyle  = (attr, callback) => appendTag('link', 'head', attr, callback);
const loadScript = (attr, callback) => appendTag('script', 'head', attr, callback);

angular.module('rando.custom').service('ITWService', function($q, $state) {
    console.log('ITWService loaded');
    const ITWService = this;

    this.getITW = function ITWService_getITW () {
        const deferred = $q.defer();

        if (window.eitinerance && window.eitinerance.core) {
            deferred.resolve(window.eitinerance.core);
        } else {
            // loadStyle({ rel: 'stylesheet', href: 'https://gadget.open-system.fr/widgets/mtef/ecrins/ecrins.preprod.min.css' });
            loadStyle({ rel: 'stylesheet', href: 'https://eitinerancecdn.open-system.fr/widgets-eiti/core/core.min.css' });
            loadStyle({ rel: 'stylesheet', href: 'https://eitinerancecdn.open-system.fr/widgets-eiti/parcdesecrins/parcdesecrins.min.css' });
            loadScript(
                { src: 'https://gadget.open-system.fr/widgets-libs/rel/noyau-2.0.min.js', type: 'text/javascript', 'xml:space': 'preserve' },
                () => loadScript(
                    { src: 'https://eitinerancecdn.open-system.fr/widgets-eiti/core/core.min.js', type: 'text/javascript', 'xml:space': 'preserve' },
                    () => loadScript(
                        { src: 'https://eitinerancecdn.open-system.fr/widgets-eiti/parcdesecrins/parcdesecrins.min.js', type: 'text/javascript', 'xml:space': 'preserve' },
                        () => deferred.resolve(window.eitinerance.core)
                    )
                )
            );
        }

        return deferred.promise;
    };

    this.pageChange = function ITWService_pageChange (pageChangeData) {
        // console.log('ITWService.pageChange() called', pageChangeData.pageName, pageChangeData);
        const pageName = pageChangeData.pageName;

        switch (pageName) {
            case 'RoutePage':
                window.history.back();
                break;

            default:
                $state.go('layout.flat', { flatID: 43 });
                ITWService.getITW()
                  .then(ITW => ITW.pages.getSinglePageApplicationClient().executePage());
        }
    }

});
