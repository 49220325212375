'use strict';

function customRandomContent() {
    return {
        restrict: 'E',
        replace: true,
        template: require('./templates/custom-random-content.html'),
        scope: {
            category: '@'
        },
        controller: 'customRandomElementController'
    };
}

function customNewsFeed() {
    return {
        restrict: 'E',
        replace: true,
        template: require('./templates/custom-news-feed.html'),
        scope: {},
        controller: 'customNewsFeedController'
    };
}

// function customPageFooter() {
//     return {
//         restrict: 'E',
//         replace: true,
//         // scope: true,
//         template: require('./templates/custom-detail-page-footer.html'),
//         controller: 'detailPageFooterController'
//     };
// }

// angular.module('rando.custom').directive('detailPageFooter', customPageFooter);
angular.module('rando.custom').directive('customRandomContent', customRandomContent);
angular.module('rando.custom').directive('customNewsFeed', customNewsFeed);

angular.module('rando.custom').directive('customCode', function customCode() {
  return {
      controller: function customCodeController (ITWService) {
          ITWService.getITW();
      }
  };
});

angular.module('rando.custom').directive('customDetailAside', function customDetailAside() {
    return {
        replace: true,
        template: require('./templates/custom-detail-aside.html'),
        controller: function customDetailAsideController (ITWService, $scope, $stateParams) {
            console.log('customDetailAsideController called !!');
            const rawResult   = $scope.$parent.result || {};
            const routeId     = rawResult.properties.reservation_id;
            $scope.routeId = routeId;

            if (!routeId) {
                console.log('No routeId for this element.');
                return;
            }

            let retries = 0;

            const callSPA = ITW => {
                if (!ITW.pages || !ITW.pages.getSinglePageApplicationClient) {
                    if (retries++ < 5) {
                        const ms = 100 * retries;
                        console.error('ITW not fully loaded. Retrying in ' + ms + 'ms…');
                        setTimeout(() => callSPA(ITW), ms);
                        return;
                    }
                    console.log('abording…');
                    return;
                }

                console.log('calling getSinglePageApplicationClient().executePage()');
                ITW.pages.getSinglePageApplicationClient({
                    layer: {
                        routeId: routeId,
                        langue: 'fr',
                        partner: 'parcdesecrins',
                        urlApiSession: 'https://e-iti-session.open-system.fr/v2',
                    },
                    pageChange: ITWService.pageChange
                }).executePage();

                if ($stateParams.action === 'book') {
                    ITWService.pageChange('FullView');
                }
            };

            ITWService.getITW().then(callSPA);
        }
    };
});
