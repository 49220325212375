module.exports={
    "PLATFORM_ID": "geotrek-rando-GTE",
    "API_URL": "/data",
    "BACKOFFICE_URL": "http://87.98.145.147",
    "ENABLE_HTML_MODE": true,
	"ENABLE_BOOKING": true,
    "LOGO_FILE": "GTE_logo.png",
    "APPROVED_BIG": "/custom/images/epn.png",
    "APPROVED_SMALL": "/custom/images/epn-small.png",
    "APPROVED_LABEL": "Esprit Parc National",
    "FAVICON":{
        "png":"favicon.png"
    },
    "HOME_TEMPLATE_FILE": {
        "fr": "custom-home-fr.html",
        "en": "custom-home-en.html"
    },
    "HEADER_TEMPLATE_FILE": "custom-header.html",
    "RULES_FLAT_PAGES_ID": "9",
    "DEFAULT_LANGUAGE": "fr",
    "ENABLED_LANGUAGES": ["fr", "en"],
    "DEFAULT_ACTIVE_CATEGORIES": ["T2", "T4", "T8"],
    "DEFAULT_INTEREST": "children",
    "PERMANENT_TILELAYERS":[
        {
            "LAYER_URL": "http://gpp3-wxs.ign.fr/t6v6u44oruleyibx9rccuw50/geoportail/wmts?LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN-EXPRESS.STANDARD&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
            "OPTIONS": {
                "attribution": "(c) IGN Geoportail",
                "minZoom": 4,
                "maxZoom": 12
            }
        },
        {
            "LAYER_URL": "http://gpp3-wxs.ign.fr/t6v6u44oruleyibx9rccuw50/geoportail/wmts?LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
            "OPTIONS": {
                "attribution": "(c) IGN Geoportail",
                "minZoom": 13,
                "maxZoom": 17
            }
        }
    ],
    "ORTHOPHOTO_TILELAYERS_NAME": "Photo",
    "ORTHOPHOTO_TILELAYERS": {
        "LAYER_URL": "http://gpp3-wxs.ign.fr/t6v6u44oruleyibx9rccuw50/geoportail/wmts?LAYER=ORTHOIMAGERY.ORTHOPHOTOS&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        "OPTIONS": {
            "id": "satellite",
            "attribution": "(c) IGN Geoportail"
        }
    },
    "TREKS_TO_GEOJSON_ZOOM_LEVEL":10,
    "NEAR_ELEMENTS_CATEGORIES": true,
    "LEAFLET_CONF": {
        "CENTER_LATITUDE": 44.83,
        "CENTER_LONGITUDE": 6.34,
        "DEFAULT_ZOOM": 10,
        "DEFAULT_MIN_ZOOM": 6,
        "DEFAULT_MAX_ZOOM": 17
    },
    "TWITTER_ID":"pnecrins",
	"FACEBOOK_APP_ID":"1679174029008755",
	"GOOGLE_ANALYTICS_ID":"UA-7988554-11", 
    "FILTERS_DEFAULT_OPEN": true,
    "FILTER_BY_VIEWPORT_DEFAULT": true,
    "DISPLAY_ASIDES_COUNTERS": true,
    "POI_EXPANDED": true,
    "SHOW_ARROWS_ON_ROUTE": true,
    "ENABLE_STRUCTURE_FILTERING": false,
    "DURATION_FILTER": [
        { "id": 0, "label": "< 3 J"},
        { "id": 72, "label": "3 J"},
        { "id": 168, "label": "7 J"},
        { "id": 999999, "label": "> 7 J"}
    ],
    "ASCENT_FILTER":  [
        { "id": 0, "label": "< 2500m"},
        { "id": 2500, "label": "2500m"},
        { "id": 5000, "label": "5000m"},
        { "id": 10000, "label": "10000m"},
        { "id": 999999, "label": "> 10000m"}
    ],
    "LENGTH_FILTER":  [
        { "id": 0, "label": "< 30km"},
        { "id": 30000, "label": "30km"},
        { "id": 60000, "label": "60km"},
        { "id": 100000, "label": "100km"},
        { "id": 9999999, "label": "> 100km"}
    ]
}
