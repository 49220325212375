'use strict';

function customRandomElementController($scope, resultsService, utilsFactory) {
    function getRandomContent() {
        resultsService.getRandomContentsByCategory($scope.category, 6)
            .then(
                function(randomContent) {
                    $scope.randomContent = randomContent;
                },
                function(err) {
                    console.error(err);
                }
            );
    }

    function init() {
        getRandomContent();
    }

    $scope.isSVG = utilsFactory.isSVG;
    $scope.sanitizeData = utilsFactory.sanitizeData;
    init();
}

function customNewsFeedController($scope, newsFeedService) {
    function initNewsFeed() {
        newsFeedService.getFeed()
            .then(
                function (newsFeed) {
                    $scope.newsFeed = newsFeed;
                },
                function (err) {
                    console.error('couldn\'t get news feed: ', err);
                }
            );
    }

    initNewsFeed();
}

// function detailPageFooterController ($scope) {
//     var reg = $scope.$watch('result', function (result) {
//         if (typeof result !== 'undefined') {
//             reg();

//             if (result.properties && result.properties.reservation_id) {

//                 var CodeUI        = result.properties.reservation_id;
//                 var idIntegration = 328;
//                 var idPanier      = 'Z3VnZ2c';

//                 var placeholder   = document.querySelector('.id-placeholder');
//                 var idPrefix      = placeholder.getAttribute('data-prefix');

//                 var urls          = {
//                     js: 'http://gadget.open-system.fr/widgets-libs/rel/noyau-1.0.min.js',
//                 };

//                 placeholder.id    = idPrefix + CodeUI;
//                 var createWidget  = function createWidget () {
//                     var widgetOptions = {
//                         idPanier: idPanier,
//                         idIntegration: idIntegration,
//                         langue: "fr",
//                         ui: CodeUI
//                     };
//                     var widgetProduit = AllianceReseaux.Widget.Instance("Produit", widgetOptions);
//                     widgetProduit.PreApp('moteur.enLigne', false);
//                     widgetProduit.Initialise();
//                 }

//                 jQuery.getScript(urls.js, createWidget);
//             }
//         }
//     });
// }

function LayoutController($rootScope, $scope, $state, $location, resultsService, filtersService, globalSettings, homeService, $translate, $timeout, Analytics, mapService) {
    $rootScope.currentState_name = $state.current.name;
    $rootScope.showFooterOnApp = globalSettings.SHOW_FOOTER;
    $rootScope.elementsLoading = 0;
    $rootScope.mapIsShown = false;
    $rootScope.placeHolderImage = globalSettings.PLACEHOLDER_IMAGE ? '/custom/images/' + globalSettings.PLACEHOLDER_IMAGE : '/images/placeholder.png';
    $rootScope.favoriteIcon = (globalSettings.FAVORITES_ICON ? globalSettings.FAVORITES_ICON : 'heart');

    var bowser = require('bowser');
    $rootScope.isChrome = !!bowser.chrome;
    $rootScope.isIE = !!bowser.msie;
    $rootScope.isEdge = !!bowser.msedge;
    $rootScope.isSafari = !!bowser.safari;
    $rootScope.isWebkit = !!bowser.webkit;

    var base = '';
    if (globalSettings.FAVICON) {
        base = '/custom/images/';
        $rootScope.favIcon = {
            png: base + globalSettings.FAVICON.png,
            ico: base + globalSettings.FAVICON.ico
        };
    } else {
        base = '/images/';
        $rootScope.favIcon = {
            png: base + 'favicon-geotrek.png',
            ico: base + 'favicon-geotrek.ico'
        };
    }

    function iniDefaultMeta() {
        if ($state.current.name === 'layout.root') {
            $translate(['DEFAULT_META_TITLE', 'DEFAULT_META_DESCRIPTION'])
                .then(
                    function(translation) {
                        $rootScope.metaTitle = translation.DEFAULT_META_TITLE;
                        $rootScope.metaDescription = translation.DEFAULT_META_DESCRIPTION;
                    },
                    function(err) {
                        if (console) {
                            console.error(err);
                        }
                    }
                );
        }
    }

    $scope.foldResults = false;
    $scope.resultsPaneToggle = function resultsPaneToggle() {
        $scope.foldResults = !$scope.foldResults;
        setTimeout(function() {
            mapService.invalidateSize();
        }, 350);
    };

    $scope.accessSpecificCategory = function accessSpecificCategory(currentCategory) {
        $state.go('layout.root');
        if (typeof currentCategory !== 'object') {
            currentCategory = [currentCategory];
        }
        if(typeof $rootScope.activeFilters["T4_eLength"] !== 'undefined'){
            delete $rootScope.activeFilters["T4_eLength"];
        }
        $rootScope.activeFilters.categories = currentCategory;
        $rootScope.activeFilters.search = "";
        filtersService.updateActiveFilters($rootScope.activeFilters);
        $rootScope.$broadcast('updateResultsList', true);

        $rootScope.activeFiltersTags = filtersService.getTagFilters();

        if ($rootScope.showHome) {
            $rootScope.showHome = false;
        }
    };

    $scope.accessSpecificCategoryAndRoute = function accessSpecificCategoryAndRoute(currentCategory, currentRoute) {
        $state.go('layout.root');
        if (typeof currentCategory !== 'object') {
            currentCategory = [currentCategory];
        }
        if (typeof currentRoute !== 'object') {
            currentRoute = [currentRoute];
        }
        if(typeof $rootScope.activeFilters["T4_eLength"] !== 'undefined'){
            delete $rootScope.activeFilters["T4_eLength"];
        }
        $rootScope.activeFilters.categories = currentCategory;
        $rootScope.activeFilters[currentCategory + "_route"] = currentRoute;
        filtersService.updateActiveFilters($rootScope.activeFilters);
        $rootScope.$broadcast('updateResultsList', true);

        $rootScope.activeFiltersTags = filtersService.getTagFilters();

        if ($rootScope.showHome) {
            $rootScope.showHome = false;
        }
    };
    $scope.accessSpecificELength = function accessSpecificELength(currentCategory, currentRoute) {
        $state.go('layout.root');
        if (typeof currentCategory !== 'object') {
            currentCategory = [currentCategory];
        }
        if (typeof currentRoute !== 'object') {
            currentRoute = [currentRoute];
        }
        if(typeof $rootScope.activeFilters.search !== 'undefined'){
            delete $rootScope.activeFilters.search;
        }
        $rootScope.activeFilters.categories = currentCategory;
        currentCategory.forEach((maCategory, index) => {
            $rootScope.activeFilters[maCategory + "_eLength"] = currentRoute;
        });
        filtersService.updateActiveFilters($rootScope.activeFilters);
        $rootScope.$broadcast('updateResultsList', true);

        $rootScope.activeFiltersTags = filtersService.getTagFilters();

        if ($rootScope.showHome) {
            $rootScope.showHome = false;
        }
    }
    $scope.accessSpecificSearch= function accessSpecificSearch(maSearch) {
        $state.go('layout.root');
        if(typeof $rootScope.activeFilters["T4_eLength"] !== 'undefined'){
            delete $rootScope.activeFilters["T4_eLength"];
        }
        let currentCategory=['T1','T2','T3','T4','T8'];
        $rootScope.activeFilters.categories = currentCategory;
        $rootScope.activeFilters.search = maSearch;
        filtersService.updateActiveFilters($rootScope.activeFilters);
        $rootScope.$broadcast('updateResultsList', true);

        $rootScope.activeFiltersTags = filtersService.getTagFilters();

        if ($rootScope.showHome) {
            $rootScope.showHome = false;
        }
    };

    iniDefaultMeta();

    if ($state.current.name === 'layout.root') {
        if (globalSettings.SHOW_HOME && angular.equals({}, $location.search())) {
            $rootScope.showHome = !homeService.getChoice();
        }
    } else {
        $rootScope.showHome = false;
    }

    var rootScopeEvents = [

        $rootScope.$on("$stateChangeSuccess", function(event, toState, toParams, fromState, fromParams) {
            // to be used for back button //won't work when page is reloaded.
            $rootScope.previousState_name = fromState.name;
            $rootScope.currentState_name = toState.name;

            if (toState.name === 'layout.root') {
                iniDefaultMeta();
            }
        })
    ];
    //back button function called from back button's ng-click="back()"
    $rootScope.back = function back() {
        if (!$rootScope.previousState_name || $rootScope.previousState_name !== 'layout.root') {
            $state.go('layout.root');
        } else {
            window.history.back();
        }
    };

    rootScopeEvents.push(
        $rootScope.$on('startSwitchGlobalLang', function() {
            resultsService.getAllResults()
                .then(
                    function() {
                        $rootScope.$emit('switchGlobalLang');
                    }
                );
        })
    );

    $scope.$on('$destroy', function() { rootScopeEvents.forEach(function(dereg) { dereg(); }); });

}
function headerController($scope, $rootScope, globalSettings) {
    $scope.mobileCollapsed = true;

    $scope.toggleMobileMenu = function toggleMobileMenu() {
        $scope.mobileCollapsed = !$scope.mobileCollapsed;
    };

    $scope.displayHomePage = function displayHomePage () {
        if (globalSettings.SHOW_HOME) {
            $rootScope.showHome = true;
        }
    };
    $scope.resetFilters = function resetFilters () {
        let urlOrigine = document.location.href;
        let url=urlOrigine.split('/');
        history.pushState({ path: this.path }, '', url[0]+'//'+url[2]);
        if (typeof filtersService !== 'undefined') {
            filtersService.resetActiveFilters();
            $rootScope.activeFilters = filtersService.getActiveFilters();
            $rootScope.$broadcast('resetRange', {filter: 'all'});
        }
    };

    $scope.switchMap = function switchMap () {
        $rootScope.mapIsShown = !$rootScope.mapIsShown;
        $rootScope.showWarningPanel = false;
    };

    $scope.logo = globalSettings.LOGO_FILE;

    $scope.switchIcons = {
        map: '/images/icons/map.svg',
        textual: '/images/icons/android-list.svg'
    };

    var rootScopeEvents = [
        $rootScope.$on('$stateChangeSuccess', function (event, toState) {
            if (toState.name === 'layout.root') {
                $scope.switchIcons.textual = '/images/icons/android-list.svg';
            } else if (toState.name === 'layout.detail') {
                $scope.switchIcons.textual = '/images/icons/document-text.svg';
            }
        })
    ];

    $scope.$on('$destroy', function () { rootScopeEvents.forEach(function (dereg) { dereg(); }); });
}
angular.module('rando.custom').controller('headerController', headerController);
angular.module('rando.custom').controller('LayoutController', LayoutController);
// angular.module('rando.custom').controller('detailPageFooterController', detailPageFooterController);
angular.module('rando.custom').controller('customRandomElementController', customRandomElementController);
angular.module('rando.custom').controller('customNewsFeedController', customNewsFeedController);
